import { FC } from "react";
import ICardComponent from "../../interfaces/cardComponent";
import { Link } from "react-router-dom";
import YouTubeVideo from "../youTubeVideo";

const GridCard: FC<ICardComponent> = ({
  id,
  title,
  image,
  description,
  linkText,
  category,
  link,
  videoId,
  linkIsExternal,
}) => {
  return (
    <div className="px-0 md:px-4 py-4 md:w-1/3 animate-fadeInUp">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
        {videoId ? (
          <YouTubeVideo videoId={videoId} />
        ) : (
          <img
            className="lg:h-48 md:h-36 w-full object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
            src={image}
            alt="content"
          />
        )}
        <div className="p-6 bg-white">
          <h2 className="tracking-widest text-xs title-font font-medium text-primaryColor mb-1 uppercase">
            {category}
          </h2>
          <h1 className="title-font text-lg font-bold text-gray-900 mb-3">
            {title}
          </h1>
          <p className="leading-relaxed text-gray-700 mb-3 line-clamp-4">
            {description}
          </p>
          <div className="flex items-center flex-wrap">
            {linkIsExternal ? (
              <Link
                to={`${link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primaryColor inline-flex items-center md:mb-2 lg:mb-0 transition-transform duration-300 ease-in-out transform hover:translate-x-1"
              >
                {linkText}
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            ) : (
              <Link
                to={`${link}/${id}`}
                className="text-primaryColor inline-flex items-center md:mb-2 lg:mb-0 transition-transform duration-300 ease-in-out transform hover:translate-x-1"
              >
                {linkText}
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridCard;
