import { useEffect, useState } from "react";
import GridCard from "../../components/gridCard";
import PageTitle from "../../components/pageTitle";
import { researchList } from "../../data/research";
import StackCard from "../../components/stackCard";

const ResearchPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size to toggle between StackCard and GridCard
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile width as <= 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up the listener
  }, []);
  return (
    <div className="mx-4">
      <PageTitle text="Research" />

      {/* Cards Section */}
      <div className="flex flex-wrap mt-10 mx-[-1rem] md:mx-[-2rem]">
        {researchList
          .sort(
            (a, b) =>
              new Date(a.createdDate || 0).getTime() -
              new Date(b.createdDate || 0).getTime()
          ) // Sort by createdDate, with fallback for undefined
          .map((section) =>
            isMobile ? (
              <GridCard key={section.id} {...section} /> // Render GridCard on mobile
            ) : (
              <StackCard key={section.id} {...section} /> // Render StackCard on desktop
            )
          )}
      </div>
    </div>
  );
};

export default ResearchPage;
