import { useEffect } from "react";
import railImage from "../../assets/images/rail.jpg";
import nathiImage from "../../assets/images/nathi.jpeg";
import vrImage from "../../assets/images/vr.jpg";
import "./styles.css";
import { Link } from "react-router-dom";

const CompanyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[85vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxCompany mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>
        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-4xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            Engaging Digital Customer Experience
          </h1>
        </div>
        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Bringing pragmatism to digital customer and social intelligence
              and analytics for enhanced customer onboarding, engagement, and
              retention. Decision making is enhanced by rich data made
              accessible to the business by leveraging AI and Machine Learning
              pragmatically.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Let's talk
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* About, Leadership, and Values Section */}
      <section className="container px-5 py-12 mx-auto grid gap-12">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-primaryColor mb-4">About Us</h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-8 text-center max-w-4xl">
            Matadigits (Pty) Ltd is a South African digital solutions company
            with grand ambitions. We are aiming to leverage enterprise
            Artificial Intelligence (AI) to make businesses and public sector
            companies unlock latent value of data. It drives AI adoption in a
            subtle way enabling customer organisations to transform their
            digital environment for better outcomes. Matadigits is bringing
            pragmatism to digital customer and social intelligence and analytics
            for enhanced customer onboarding, engagement and retention.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <img
            src={nathiImage}
            alt="About Us"
            className="w-64 mb-8 rounded-lg shadow-lg transition-transform duration-700 hover:scale-105"
          />
          <h2 className="text-2xl text-primaryColor mb-4">Leadership</h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-8 text-center max-w-4xl">
            Nathi Sukazi founded Matadigits (Pty) Ltd as a niche AI-powered
            digital solutions company. Matadigits wants to play uniquely in the
            digital analytics, social intelligence, customer experience,
            onboarding, KYC, and decision analysis spaces. Nathi is an
            entrepreneur who started out as a financial & telecoms journalist.
            He later spent most of his 20 years’ working career in PR, Marketing
            & Communications building reputation of individuals, brands,
            corporates and organisations – mostly in the technology space. He
            holds several degrees including a MSc in Innovation &
            Entrepreneurship (HEC Paris Business School) and a MA in
            International Affairs in Cybersecurity, Intelligence Analysis &
            Global Security Strategy (King’s College London).
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-primaryColor mb-4">Our Values</h2>
          <div className="container px-5 py-0 mx-auto grid gap-8 md:grid-cols-3 text-center">
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="mb-4 text-primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="text-xl mb-2">Contextual Innovation</h3>
              <p className="text-gray-600">
                We create solutions that are innovative and tailored to the
                specific context of our clients.
              </p>
            </div>

            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="mb-4 text-primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 19a9 9 0 100-18 9 9 0 000 18zm0 0v-9m0 0H3m8 0h8"
                  />
                </svg>
              </div>
              <h3 className="text-xl mb-2">Data Insights</h3>
              <p className="text-gray-600">
                We harness the power of data to deliver actionable insights that
                drive business success.
              </p>
            </div>

            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="mb-4 text-primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 11h4m0 0V7m0 4h4m6 10h-4a2 2 0 01-2-2v-2m0-10V7a2 2 0 012-2h4m-4 0v10m0 0V5m0 4h4M6 21v-2a2 2 0 00-2-2H3a2 2 0 00-2 2v2m0-6V5a2 2 0 012-2h2a2 2 0 012 2v10m0-6V5a2 2 0 012-2h2a2 2 0 012 2v6m-4 0h-4m0 4h4"
                  />
                </svg>
              </div>
              <h3 className="text-xl mb-2">Client Engagement</h3>
              <p className="text-gray-600">
                We believe that deep client engagement is the source of lasting
                solutions and enduring partnerships.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Partners and Careers Section */}
      <section className="container px-5 py-12 mx-auto grid gap-12 md:grid-cols-2 items-start">
        <div className="flex flex-col">
          <h2 className="text-2xl text-primaryColor mb-4">Partners</h2>
          <img
            src={railImage}
            alt="Partners"
            className="w-full h-64 mb-4 rounded-lg shadow-lg transition-transform duration-700 hover:scale-105 object-cover "
          />
          <p className="text-lg text-gray-700 leading-relaxed">
            Matadigits is partnering innovative emerging technology partners who
            are changing the game in digital customer analytics, leveraging
            Artificial Intelligence and Machine Learning. They come from across
            the globe and include the following: <br />
            <br />
            Twilio Segment, Audiense, Data.ai, Method, Gainsight, ChaosSearch,
            Spryker, Inboxy.io, Tractable, PluralSight, Use.ID, Scrubby,
            Outreach, Acceleraid, etc.
          </p>
        </div>

        <div className="flex flex-col">
          <h2 className="text-2xl text-primaryColor mb-4">Careers</h2>
          <img
            src={vrImage}
            alt="Careers"
            className="w-full h-64 mb-4 rounded-lg shadow-lg transition-transform duration-700 hover:scale-105 object-cover "
          />
          <p className="text-lg text-gray-700 leading-relaxed">
            Join us in shaping the future of customer data leverage. Matadigits
            is looking for enterprising & innovation-driven team workers. We’re
            looking for teammates who believe in making AI not only responsible,
            but pragmatic for ordinary folks. These are determined individuals
            who embrace diverse backgrounds & opinions, & who thrive in
            challenging, open, & transparent environments.
          </p>
        </div>
      </section>
    </div>
  );
};

export default CompanyPage;
