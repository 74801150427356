import ICardComponent from "../interfaces/cardComponent";

const stories: Array<ICardComponent> = [
  {
    id: "1",
    category: "Sports",
    image:
      "https://images.pexels.com/photos/4614165/pexels-photo-4614165.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    title: "Raclette Blueberry Nextious Level",

    description:
      "The risk for intimidation, malicious damage to property, arson, barricading of roads, looting, hijacking of trucks as well as clashes between members/supporters of the MK Party, opposing political parties and with authorities is high, should demonstrations take place,” the note says. Fidelity Intelligence Services issued a similar warning two days later",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Khumalo",
    createdDate: "2021-07-14",
  },
  {
    id: "2",
    category: "News",
    image:
      "https://images.pexels.com/photos/2369217/pexels-photo-2369217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Ennui Snackwave Thundercats",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Khumalo",
    createdDate: "2021-07-14",
  },
  {
    id: "3",
    category: "Entertainment",
    image:
      "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Selvage Poke Waistcoat Godard",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Sophy Madiba",
    createdDate: "2021-07-14",
  },
];

const documentaries: Array<ICardComponent> = [
  {
    id: "1",
    category: "Sports",
    image:
      "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    title: "Raclette Blueberry Nextious Level",

    description:
      "The risk for intimidation, malicious damage to property, arson, barricading of roads, looting, hijacking of trucks as well as clashes between members/supporters of the MK Party, opposing political parties and with authorities is high, should demonstrations take place,” the note says. Fidelity Intelligence Services issued a similar warning two days later",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Khumalo",
    createdDate: "2021-07-14",
    videoId: "gLa8WFDlFkE",
  },
  {
    id: "2",
    category: "News",
    image:
      "https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Ennui Snackwave Thundercats",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Jane Nyathi",
    createdDate: "2021-07-14",
    videoId: "t9jEmAtYPPY",
  },
  {
    id: "3",
    category: "Entertainment",
    image:
      "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Selvage Poke Waistcoat Godard",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Sophie Mokoena",
    createdDate: "2021-07-14",
    videoId: "KmdRmn-kvR8",
  },
];

const duplicateStories: Array<ICardComponent> = [
  {
    id: "1",
    category: "Sports",
    image:
      "https://images.pexels.com/photos/2369217/pexels-photo-2369217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    title: "Raclette Blueberry Nextious Level",

    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Sophie Mokoena",
    createdDate: "2021-07-14",
  },
  {
    id: "2",
    category: "News",
    image:
      "https://images.pexels.com/photos/4614165/pexels-photo-4614165.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Ennui Snackwave Thundercats",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Sophie Mokoena",
    createdDate: "2021-07-14",
  },
  {
    id: "3",
    category: "Entertainment",
    image:
      "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Selvage Poke Waistcoat Godard",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Mokoena",
    createdDate: "2021-07-14",
  },
  {
    id: "4",
    category: "Sports",
    image:
      "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    title: "Raclette Blueberry Nextious Level",

    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Mokoena",
    createdDate: "2021-07-14",
  },
  {
    id: "5",
    category: "News",
    image:
      "https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Ennui Snackwave Thundercats",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Mokoena",
    createdDate: "2021-07-14",
  },
  {
    id: "6",
    category: "Entertainment",
    image:
      "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Selvage Poke Waistcoat Godard",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Zanele Mokoena",
    createdDate: "2021-07-14",
  },
];

const podcasts: Array<ICardComponent> = [
  {
    id: "1",
    category: "Sports",
    image:
      "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    title: "Raclette Blueberry Nextious Level",

    description:
      "The risk for intimidation, malicious damage to property, arson, barricading of roads, looting, hijacking of trucks as well as clashes between members/supporters of the MK Party, opposing political parties and with authorities is high, should demonstrations take place,” the note says. Fidelity Intelligence Services issued a similar warning two days later",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "John Doe",
    createdDate: "2021-07-14",
    videoId: "64QjdBzdEsw",
  },
  {
    id: "2",
    category: "News",
    image:
      "https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Ennui Snackwave Thundercats",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Jane Doe",
    createdDate: "2021-07-14",
    videoId: "9u1rf0eV_PE",
  },
  {
    id: "3",
    category: "Entertainment",
    image:
      "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Selvage Poke Waistcoat Godard",
    description:
      "Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.",
    link: "/storydetails",
    linkText: "Read more",
    createdBy: "Sophy Doe",
    createdDate: "2021-07-14",
    videoId: "jDyFv1hEFdk",
  },
];

const mainPodcast: ICardComponent = {
  id: "1",
  category: "Sports",
  image:
    "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

  title: "Raclette Blueberry Nextious Level",

  description:
    "The risk for intimidation, malicious damage to property, arson, barricading of roads, looting, hijacking of trucks as well as clashes between members/supporters of the MK Party, opposing political parties and with authorities is high, should demonstrations take place,” the note says. Fidelity Intelligence Services issued a similar warning two days later",
  link: "/storydetails",
  linkText: "Read more",
  createdBy: "John Doe",
  createdDate: "2021-07-14",
  videoId: "OS0KhOmYY40",
};

export { stories, duplicateStories, podcasts, mainPodcast, documentaries };
