import { useEffect } from "react";
import Button from "../../components/button";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 pt-5 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-4xl text-3xl title-font mb-4 text-gray-900 animate-fadeInDown">
            Get in Touch with Us
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base animate-fadeInUp">
            Have questions or need support? Fill out the form below, and we’ll
            be in touch soon.
          </p>
        </div>

        <div className="lg:w-2/2 md:w-2/3 mx-auto mb-12">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2 animate-fadeInLeft">
              <div className="relative">
                <label className="leading-7 text-sm text-gray-600">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-1/2 animate-fadeInRight">
              <div className="relative">
                <label className="leading-7 text-sm text-gray-600">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full animate-fadeInUp">
              <div className="relative">
                <label className="leading-7 text-sm text-gray-600">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div className="p-2 w-full animate-fadeInUp">
              <Button text="Submit" onClick={() => {}} />
            </div>
          </div>
        </div>

        <div className="lg:w-2/2 md:w-2/3 mx-auto">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 animate-fadeInUp">
            <a
              href="mailto:nathi@matadigits.xyz"
              className="block p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <h2 className="text-base text-primaryColor">Email</h2>
              <p className="text-gray-600  text-sm">nathi@matadigits.xyz</p>
            </a>

            <a
              href="mailto:support@matadigits.xyz"
              className="block p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <h2 className="text-base text-primaryColor">Support</h2>
              <p className="text-gray-600 text-sm">support@matadigits.xyz</p>
            </a>

            <a
              href="tel:0810180518"
              className="block p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <h2 className="text-base text-primaryColor">Mobile</h2>
              <p className="text-gray-600 text-sm">0810180518</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsPage;
