import ICardComponent from "../interfaces/cardComponent";

const newsList: Array<ICardComponent> = [
  {
    id: "1",
    category: "AI",
    image:
      "https://techcrunch.com/wp-content/uploads/2024/07/GettyImages-2156422057.jpg?resize=2048,1355",

    title:
      "How Apple Intelligence is changing the way you use Siri on your iPhone ",
    description:
      "Following years of talk around Apple’s lack of innovation when it comes to Siri, Apple has promised the launch of a smarter version of its digital assistant coming this fall.",
    link: "https://sensortower.com/blog/the-latest-trends-shaping-the-mobile-gaming-ecosystem",
    linkText: "Read more",
    createdBy: "Tech Crunch",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "2",
    category: "Tech",
    image:
      "https://media.newyorker.com/photos/66d7765106de9506b4284a25/2:2/w_2240,c_limit/NewYorker_Telegram_final2%20.jpg",

    title:
      "The Arrest of Telegram’s Founder Illuminates Global Anxieties About Social Platforms",
    description:
      "Pavel Durov may have been detained for the company’s alleged illegal conduct, but his predicament is also a signal of government concern about digital networks’ outsized power.",
    link: "https://www.newyorker.com/culture/infinite-scroll/the-arrest-of-telegrams-founder-illuminates-global-anxieties-about-social-platforms?utm_source=nl&utm_brand=tny&utm_mailing=TNY_Daily_Free_090424&utm_campaign=aud-dev&utm_medium=email&utm_term=tny_daily_digest&bxid=5bd67f4c24c17c1048035390&cndid=49696991&hasha=c4659477cf28cf5b89e4af1bf4f071c8&hashb=8b8685376f98b59a926b6fda43bdc6ecb3ab3518&hashc=12978af517d78d127edd1875f201ec7fd44d70c421434449b62bc7685794f70c&esrc=slim-article-newsletter&mbid=CRMNYR012019",
    linkText: "Read more",
    createdBy: "The New Yorker",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "3",
    category: "Business",
    image:
      "https://media.wired.com/photos/66d1e20ecc85195f7bd91847/master/w_2240,c_limit/Business_Brazil_X_GettyImages-2168330797.jpg",

    title: "A Judge Has Banned Elon Musk's X in Brazil",
    description:
      "An ongoing legal row over accounts allegedly spreading disinformation has escalated between Elon Musk and Brazil’s top court.",
    link: "https://www.wired.com/story/elon-musks-standoff-with-brazil-reaches-a-tipping-point/?utm_source=nl&utm_brand=wired&utm_mailing=WIR_FastForward_090424&utm_campaign=aud-dev&utm_medium=email&utm_content=WIR_FastForward_090424&bxid=5bd67f4c24c17c1048035390&cndid=49696991&hasha=c4659477cf28cf5b89e4af1bf4f071c8&hashb=8b8685376f98b59a926b6fda43bdc6ecb3ab3518&hashc=12978af517d78d127edd1875f201ec7fd44d70c421434449b62bc7685794f70c&esrc=bounceXmulti&utm_term=WIR_Transportation",
    linkText: "Read more",
    createdBy: "Wired",
    createdDate: "2024-08-30",
    linkIsExternal: true,
  },
  {
    id: "4",
    category: "Instant messaging",
    image:
      "https://media.wired.com/photos/66d0565125ed02ad7c7eacaf/master/w_2240,c_limit/GettyImages-2167971812.jpg",
    title: "Telegram Faces a Reckoning in Europe. Other Founders Should Beware",
    description:
      "Politicians have long complained of Telegram CEO Pavel Durov’s attitude. What’s changed is their response.",
    link: "https://www.wired.com/story/telegram-faces-a-reckoning-in-europe-other-founders-should-beware/?utm_source=nl&utm_brand=wired&utm_mailing=WIR_FastForward_090424&utm_campaign=aud-dev&utm_medium=email&utm_content=WIR_FastForward_090424&bxid=5bd67f4c24c17c1048035390&cndid=49696991&hasha=c4659477cf28cf5b89e4af1bf4f071c8&hashb=8b8685376f98b59a926b6fda43bdc6ecb3ab3518&hashc=12978af517d78d127edd1875f201ec7fd44d70c421434449b62bc7685794f70c&esrc=bounceXmulti&utm_term=WIR_Transportation",
    linkText: "Read more",
    createdBy: "Wired",
    createdDate: "2024-08-29",
    linkIsExternal: true,
  },
  {
    id: "5",
    category: "AI",
    image:
      "https://techcrunch.com/wp-content/uploads/2016/12/screen-shot-2016-12-05-at-5-33-24-am.png",
    title: "Uber acquires Geometric Intelligence to create an AI lab",
    description:
      "Ride-hailing requires a lot of machine smarts to maintain a competitive edge, so it’s not surprising to see Uber make a strategic acquisition in the artificial intelligence space.",
    link: "https://techcrunch.com/2016/12/05/uber-acquires-geometric-intelligence-to-create-an-ai-lab/",
    linkText: "Read more",
    createdBy: "SensorTower",
    createdDate: "2016-12-05",
    linkIsExternal: true,
  },
  {
    id: "6",
    category: "AI",
    image:
      "https://media.wired.com/photos/66d76623fb4327b24c9ef2c1/1:1/w_2240,c_limit/AI-Lab-Robot-Warehouse-Amazon-Business-517753762.jpg",
    title: "This Could Be the Start of Amazon’s Next Robot Revolution",
    description:
      "The ecommerce giant has acquired a team to give robots greater intelligence and dexterity—potentially automating much more of its warehouse operations.",
    link: "https://www.wired.com/amazon-covariant-robotics-deal/",
    linkText: "Read more",
    createdBy: "Wired",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "7",
    category: "Politics",
    image:
      "https://media.wired.com/photos/66609b9f51e70775068bb362/master/w_2240,c_limit/Eliot%20higgins_Portraits%20Selection_The%20Big%20Interview_Wired_@michaelfungphotography-3.jpeg",
    title: "How to Lead an Army of Digital Sleuths in the Age of AI",
    description:
      "Eliot Higgins and his 28,000 forensic foot soldiers at Bellingcat have kept a miraculous nose for truth—and a sharp sense of its limits—in Gaza, Ukraine, and everywhere else atrocities hide online.",
    link: "https://www.wired.com/story/how-to-lead-army-of-digital-sleuths-age-of-ai/",
    linkText: "Read more",
    createdBy: "Wired",
    createdDate: "2024-06-06",
    linkIsExternal: true,
  },
  {
    id: "8",
    category: "Business",
    image:
      "https://www.pymnts.com/wp-content/uploads/2024/08/Weave-healthcare-payments.jpg?w=1000&h=600&crop=1",
    title: "The Hidden Cost of Manual AR Processes",
    description:
      "It’s almost 2025, but many enterprise firms are still chasing paper when it comes to their accounts receivable (AR).",
    link: "https://www.pymnts.com/accounts-receivable/2024/the-hidden-cost-of-manual-ar-processes/",
    linkText: "Read more",
    createdBy: "PYMNTS",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "9",
    category: "Data Analytics",
    image:
      "https://www.pymnts.com/wp-content/uploads/2021/09/Commerce-Department-artificial-intelligence.jpg?w=768",
    title: "How Zillennials Are Driving Innovation in Financial Services",
    description:
      "PYMNTS Intelligence data finds that zillennials — a microgeneration comprising older members of Generation Z and younger millennials — are leading the way in mobile-banking adoption, even as they continue to transact with traditional banks.",
    link: "https://www.pymnts.com/accounts-receivable/2024/the-hidden-cost-of-manual-ar-processes/",
    linkText: "Read more",
    createdBy: "PYMNTS",
    createdDate: "2024-09-01",
    linkIsExternal: true,
  },
  {
    id: "10",
    category: "Business",
    image:
      "https://www.pymnts.com/wp-content/uploads/2024/09/Thailand-digital-wallet.jpg?w=768",
    title: "Thailand Adds Cash Payments to $13 Billion Digital Wallet Plan",
    description:
      "Thailand’s digital wallet stimulus plan has suddenly become less digital.",
    link: "https://www.pymnts.com/mobile-wallets/2024/thailand-adds-cash-payments-to-13-billion-digital-wallet-plan?utm_source=Sailthru&utm_medium=email&utm_campaign=Main%20NL%2009/04&utm_term=Main%20Newsletter",
    linkText: "Read more",
    createdBy: "PYMNTS",
    createdDate: "2024-09-03",
    linkIsExternal: true,
  },
  {
    id: "11",
    category: "Business",
    image:
      "https://pitchbook.brightspotcdn.com/dims4/default/d111df8/2147483647/strip/true/crop/2352x980+12+0/resize/2880x1200!/format/webp/quality/90/?url=https%3A%2F%2Fk2-prod-pitchbook-prod.s3.us-east-1.amazonaws.com%2Fbrightspot%2F11%2F08%2Fa2934bd4aeb6bd14c14832e3d456%2Fipo-blog-asset-images-v3-card-1.png",
    title: "Blackstone bulks up on data centers in $16B AirTrunk deal",
    description:
      "Blackstone said it has agreed to buy Australian data-center operator AirTrunk in a deal that values the company at A$24 billion ($16 billion), marking the fast-growing industry’s biggest acquisition to date.",
    link: "https://pitchbook.com/news/articles/blackstone-data-center-operators-airtrunk-acquisition?utm_medium=newsletter&utm_source=daily_pitch&utm_campaign=EU_news&utm_content=researchnews&utm_term&sourceType=NEWSLETTER",
    linkText: "Read more",
    createdBy: "PitchBook",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "12",
    category: "Tech",
    image: "https://techcrunch.com/wp-content/uploads/2024/09/Boom-feat.jpg",
    title:
      "Boom’s macOS camera app lets you customize your video call appearance",
    description:
      "As someone who talks to many people outside my time zone, I often spend at least a few minutes on a video call explaining my location, time and weather.",
    link: "https://techcrunch.com/2024/09/04/boom-is-a-macos-camera-app-for-customizing-your-video-call-layout/",
    linkText: "Read more",
    createdBy: "TechCrunch",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "13",
    category: "Tech",
    image:
      "https://www.smartcitiesdive.com/imgproxy/YOgIKKjXdmtNtuA5TZskIlVDpBWmk9bTPfHT1Ffnm2w/g:ce/rs:fill:2000:1125:1/bG9jYWw6Ly8vZGl2ZWltYWdlL0dldHR5SW1hZ2VzLTEyOTc5MTQ2NDkuanBn.webp",
    title: "Smart Cities Technology and Data",
    description:
      "After years of research, cities are beginning to use advanced technologies and data to address real-world problems like homelessness and the digital divide.",
    link: "https://www.smartcitiesdive.com/trendline/smart-cities-technology-and-data/348/?utm_source=SMCD&utm_medium=Library&utm_campaign=CivicPlus&utm_term=Smart%20Cities%20Dive",
    linkText: "Read more",
    createdBy: "SmartCitiesDive",
    createdDate: "2024-09-04",
    linkIsExternal: true,
  },
  {
    id: "14",
    category: "Tech",
    image:
      "https://www.smartcitiesdive.com/imgproxy/_XjfaA77pz5lwGwWD6YpYeXGlMGafQ5AL3Noy4AUiSc/g:ce/rs:fill:1600:900:1/bG9jYWw6Ly8vZGl2ZWltYWdlL0NvY29fT25fRGVsaXZlcnkuanBn.webp",
    title: "Uber expands robotic delivery",
    description:
      "Urban robot delivery company Coco started delivering for Uber Eats in Los Angeles this week. It will expand to more cities in coming months.",
    link: "https://www.smartcitiesdive.com/news/uber-eats-coco-robotic-delivery-los-angeles/725760/",
    linkText: "Read more",
    createdBy: "SmartCitiesDive",
    createdDate: "2024-08-30",
    linkIsExternal: true,
  },
  {
    id: "15",
    category: "Tech",
    image:
      "https://media.wired.com/photos/667e04ba98603ffe59c8feb5/master/w_2580%2Cc_limit/Bose-QuietComfort-Ultra-Headphones-White-Reviewer-Photo-SOURCE-Ryan-Waniata.jpg",
    title: "The Best Noise-Canceling Headphones",
    description:
      "Tune out (or rock out) with our favorite over-ears and earbuds.",
    link: "https://www.wired.com/gallery/best-noise-canceling-headphones/",
    linkText: "Read more",
    createdBy: "Wired",
    createdDate: "2024-09-03",
    linkIsExternal: true,
  },
  {
    id: "16",
    category: "Tech",
    image:
      "https://media.newyorker.com/photos/66b4df5c38c86bc2f7b98918/master/w_2240,c_limit/06_InfoDeterminism_Final_Revised.png",
    title: "Are We Living in the Age of Info-Determinism?",
    description:
      "Increasingly, our networks seem to be steering our history in ways we don’t like and can’t control.",
    link: "https://www.newyorker.com/culture/open-questions/are-we-living-in-the-age-of-info-determinism?utm_source=nl&utm_brand=tny&utm_mailing=TNY_Daily_Free_081324&utm_campaign=aud-dev&utm_medium=email&utm_term=tny_daily_digest&bxid=5bd67f4c24c17c1048035390&cndid=49696991&hasha=c4659477cf28cf5b89e4af1bf4f071c8&hashb=8b8685376f98b59a926b6fda43bdc6ecb3ab3518&hashc=12978af517d78d127edd1875f201ec7fd44d70c421434449b62bc7685794f70c&esrc=slim-article-newsletter&mbid=CRMNYR012019",
    linkText: "Read more",
    createdBy: "The New Yorker",
    createdDate: "2024-08-13",
    linkIsExternal: true,
  },
];

export { newsList };
