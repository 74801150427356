import { useEffect } from "react";
import { Link } from "react-router-dom";
import { industriesList } from "../../data/industries";
import {
  FaIndustry,
  FaBuilding,
  FaChartLine,
  FaMedkit,
  FaPeopleCarry,
  FaShoppingBasket,
  FaPhone,
  FaHandsHelping,
  FaCar,
  FaFileVideo,
  FaPhotoVideo,
} from "react-icons/fa"; // Example Icons
import "./styles.css";

const IndustriesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getIcon = (title: string) => {
    switch (title) {
      case "Public Sector":
        return <FaPeopleCarry className="text-3xl text-primaryColor" />;
      case "Retail":
        return <FaShoppingBasket className="text-3xl text-primaryColor" />;
      case "Telecoms":
        return <FaPhone className="text-2xl text-primaryColor" />;
      case "Insurance":
        return <FaHandsHelping className="text-3xl text-primaryColor" />;
      case "Logistics":
        return <FaCar className="text-3xl text-primaryColor" />;
      case "Media":
        return <FaPhotoVideo className="text-3xl text-primaryColor" />;
      case "Automotive":
        return <FaCar className="text-3xl text-primaryColor" />;
      case "Consumer Goods":
        return <FaChartLine className="text-3xl text-primaryColor" />;
      case "Healthcare":
        return <FaMedkit className="text-3xl text-primaryColor" />;
      case "Real Estate":
        return <FaBuilding className="text-3xl text-primaryColor" />;
      default:
        return <FaIndustry className="text-3xl text-primaryColor" />;
    }
  };

  return (
    <div className="bg-white px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[55vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxIndustries mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>

        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-3xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            Industries
          </h1>
        </div>

        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Here are some insights about industries in South Africa.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Let's talk
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Industry List Section */}
      <div className="container mx-auto px-5 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {industriesList.map((industry, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 h-full"
            >
              <div className="flex items-center mb-4">
                <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
                  {getIcon(industry.title)}
                </div>
                <h2 className="text-xl text-primaryColor ml-3">
                  {industry.title}
                </h2>
              </div>
              <p className="text-gray-700 whitespace-pre-line">
                {industry.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustriesPage;
