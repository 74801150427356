import ICardComponent from "../interfaces/cardComponent";

const researchList: Array<ICardComponent> = [
  {
    id: "1",
    category: "Game intelligence",
    image:
      "https://images.ctfassets.net/vfkpgemp7ek3/4mpXzHMqgIxtbdl3GXcfgc/7aa64948a79ea8ce07e33664ae218c1f/BLOG---the-state-of-mobile-gaming.jpg?fm=webp&q=40&w=2560",

    title: "The Latest Trends Shaping The Mobile Gaming Ecosystem",
    description:
      "Mobile gaming downloads and revenue are trending lower, but some types of games are more affected than others. Here's why.",
    link: "https://sensortower.com/blog/the-latest-trends-shaping-the-mobile-gaming-ecosystem",
    linkText: "Read more",
    createdBy: "SensorTower",
    createdDate: "2023-03-01",
    linkIsExternal: true,
  },
  {
    id: "2",
    category: "Data analytics",
    image:
      "https://images.ctfassets.net/vfkpgemp7ek3/7tqAfCENPHDGtyzk9mGwRI/22722e98202b87ab5f0c879fed895e2d/resource__17_.jpg?fm=webp&q=40&w=2560",

    title: "Audience Insights Report: 2.5.24",
    description: "An Analysis of Mobile App & Digital Advertising Data.",
    link: "https://go.sensortower.com/rs/351-RWH-315/images/Sensor%20Tower_Audience%20Insights%20Report_2.5.24.pdf?version=0",
    linkText: "Read more",
    createdBy: "SensorTower",
    createdDate: "2024-05-02",
    linkIsExternal: true,
  },
  {
    id: "3",
    category: "Data analytics",
    image:
      "https://images.ctfassets.net/vfkpgemp7ek3/4jEs2vLVPu6zPRMJHHvXrD/e4aeb6b0402c8dd09fc36f00dbb3aeb1/SOM-Report-Report-GIF-2024-700x370-v2.gif?fm=webp&q=40&w=2560",

    title: "State of Mobile 2024",
    description:
      "In this mobile-centric world, app usage is at an all-time high.",
    link: "https://sensortower.com/state-of-mobile-2024",
    linkText: "Read more",
    createdBy: "SensorTower",
    createdDate: "2024-03-02",
    linkIsExternal: true,
  },
  {
    id: "4",
    category: "Data analytics",
    image:
      "https://www.gsma.com/solutions-and-impact/connectivity-for-good/mobile-economy/wp-content/plugins/plugin_flagship_templates//images/me_global_2024/ME2024_Banner_1440x810.jpg",
    title: "The Mobile Economy 2024",
    description:
      "In this mobile-centric world, app usage is at an all-time high.",
    link: "https://www.gsma.com/solutions-and-impact/connectivity-for-good/mobile-economy/wp-content/uploads/2024/02/260224-The-Mobile-Economy-2024.pdf",
    linkText: "Read more",
    createdBy: "SensorTower",
    createdDate: "2024-02-26",
    linkIsExternal: true,
  },
  {
    id: "5",
    category: "Cyber security",
    image:
      "https://www.cybersecurity-insiders.com/wp-content/uploads/Screenshot-2024-04-19-160354-321x420.png",
    title: "2024 Application Security Report",
    description:
      "In today’s digital ecosystem, the expansion of application and API landscapes offers both opportunities and challenges for organizations.",
    link: "https://www.cybersecurity-insiders.com/wp-content/uploads/2024-Application-Security-Report-Fortinet.pdf",
    linkText: "Read more",
    createdBy: "Cyber Security Insiders",
    createdDate: "2024-02-01",
    linkIsExternal: true,
  },
  {
    id: "6",
    category: "Tech",
    image:
      "https://www.broadbandcommission.org/wp-content/uploads/2024/06/State-of-Broadband-2024-Thumbnail.jpg",
    title: "The State of Broadband 2024",
    description: "Annual Flagship Report of the Broadband Commission.",
    link: "https://www.broadbandcommission.org/publication/state-of-broadband-2024/#",
    linkText: "Read more",
    createdBy: "Broadband Commission",
    createdDate: "2024-06-01",
    linkIsExternal: true,
  },
  {
    id: "7",
    category: "Tech",
    image:
      "https://data.gsmaintelligence.com/api-web/v2/research-image-download?id=79791087&file=Screenshot%202024-02-21%20102607.png",
    title: "The State of 5G in 2024",
    description: "Introducing the GSMA Intelligence 5G Connectivity Index.",
    link: "https://data.gsmaintelligence.com/research/research/research-2024/the-state-of-5g-in-2024",
    linkText: "Read more",
    createdBy: "GSMA Intelligence",
    createdDate: "2024-02-01",
    linkIsExternal: true,
  },
  {
    id: "8",
    category: "Tech",
    image:
      "https://www.gsma.com/r/wp-content/uploads/2023/10/somic_stitched_loop.gif",
    title: "The State of Mobile Internet Connectivity Report 2023",
    description:
      "Mobile internet is connecting more people to the internet than ever before.",
    link: "https://www.gsma.com/r/somic/",
    linkText: "Read more",
    createdBy: "GSMA",
    createdDate: "2024-02-01",
    linkIsExternal: true,
  },
  {
    id: "9",
    category: "Data analytics",
    image:
      "https://iot-analytics.com/wp/wp-content/uploads/2024/05/Data-Management-and-Analytics-Market-Report-2024%E2%80%932030-Product-Icon.png",
    title: "Data Management and Analytics Market Report 2024–2030",
    description:
      "A 246-page report detailing the market for data management and analytics solutions.",
    link: "https://iot-analytics.com/product/data-management-and-analytics-market-report-2024-2030/",
    linkText: "Read more",
    createdBy: "IOT Analytics",
    createdDate: "2024-05-01",
    linkIsExternal: true,
  },
  {
    id: "10",
    category: "Data analytics",
    image:
      "https://emt.gartnerweb.com/ngw/globalassets/en/newsroom/images/graphs/datrends2024.jpg",
    title:
      "Gartner Analysts Are Discussing Ways in Which Organizations Can Leverage These Trends at the Gartner Data & Analytics Summit, April 24-25 in Mumbai, India",
    description:
      "Gartner, Inc. identified the top data and analytics (D&A) trends for 2024 that are driving the emergence of a wide range of challenges, including organizational and human issues.",
    link: "https://www.gartner.com/en/newsroom/press-releases/2024-04-25-gartner-identifies-the-top-trends-in-data-and-analytics-for-2024",
    linkText: "Read more",
    createdBy: "Gartner",
    createdDate: "2024-04-25",
    linkIsExternal: true,
  },
  {
    id: "11",
    category: "AI",
    image:
      "https://www.exasol.com/app/uploads/2024/03/thumbnail-ai-report-1024x585.jpg",
    title: "AI & Analytics Report 2024",
    description:
      "Discover how AI is transforming industries, with key findings from our latest survey.",
    link: "https://www.exasol.com/resource/ai-analytics-2024-report-future-of-business-data/",
    linkText: "Read more",
    createdBy: "Exasol",
    createdDate: "2024-04-01",
    linkIsExternal: true,
  },
  {
    id: "12",
    category: "Data analytics",
    image: "https://hakkoda.io/wp-content/uploads/2024/02/Thumbnail-jpg.webp",
    title: "State of Data Report 2024",
    description:
      "What the highest and lowest performers have to teach us about the future of data modernization and Gen AI.",
    link: "https://hakkoda.io/state-of-data-report-2024/",
    linkText: "Read more",
    createdBy: "Hakkoda",
    createdDate: "2024-06-01",
    linkIsExternal: true,
  },
  {
    id: "13",
    category: "Data analytics",
    image:
      "https://go.forrester.com/wp-content/uploads/2023/03/Careers-section_Bold_1050x788.webp",
    title: "Predictions 2024: Data And Analytics",
    description:
      "AI Is Ready For The Spotlight, But Data And Analytics Determine If It Shine",
    link: "https://www.forrester.com/report/predictions-2024-data-and-analytics/RES179927",
    linkText: "Read more",
    createdBy: "Forrester",
    createdDate: "2023-10-26",
    linkIsExternal: true,
  },
  {
    id: "14",
    category: "Data analytics",
    image:
      "https://wp.salesforce.com/en-eu/wp-content/uploads/sites/11/2024/09/CHP-marquee-foreground-1.png?resize=1536,1215",
    title: "State of Data and Analytics",
    description:
      "Global insights from over 10,000 analytics, IT, and business leaders on data management and decisionmaking in the age of artificial intelligence.",
    link: "https://www.salesforce.com/content/dam/web/en_us/www/documents/research/state-of-data-analytics.pdf",
    linkText: "Read more",
    createdBy: "Salesforce",
    createdDate: "2023-10-26",
    linkIsExternal: true,
  },
];

export { researchList };
