import { useParams } from "react-router-dom";
import { stories } from "../../../data/stories";
import { useEffect, useState } from "react";
import ICardComponent from "../../../interfaces/cardComponent";
import PageTitle from "../../../components/pageTitle";
import authorImage from "../../../assets/images/author.jpg";

const StoryDetailsPage = () => {
  const { id } = useParams();
  const [story, setStory] = useState<ICardComponent | undefined>();

  useEffect(() => {
    const filteredStories = stories.find((story) => story.id == id);
    setStory(filteredStories);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="text-gray-600 body-font">
      <PageTitle text={story?.title ?? ""} />
      <div className="container px-5 py-0 mx-auto flex flex-col">
        <div className="lg:w-4/6 mx-auto">
          <div className="rounded-lg h-64 overflow-hidden">
            <img
              alt="content"
              className="object-cover object-center h-full w-full"
              src={story?.image}
            />
          </div>
          <div className="flex flex-col sm:flex-row mt-10">
            {" "}
            {story?.description}
          </div>

          {/* Author Bio Section */}
          <div className="mt-8 p-6 bg-gray-100 rounded-lg mb-10">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              About the Author
            </h3>
            <div className="flex items-center">
              <img
                src={story?.authorImage ?? authorImage}
                alt="Author"
                className="w-16 h-16 rounded-full object-cover mr-4"
              />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">
                  {story?.createdBy}
                </h4>
                <p className="text-gray-600">
                  {story?.authorBio ??
                    "Boast a decade of experience in journalism, Zanele has published articles for top news companies ranging from News24, BBC, SABC and much more.."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoryDetailsPage;
