import { FC } from "react";
import ICardComponent from "../../interfaces/cardComponent";
import { Link } from "react-router-dom";
import YouTubeVideo from "../youTubeVideo";

const StackCard: FC<ICardComponent> = ({
  id,
  title,
  image,
  description,
  linkText,
  category,
  link,
  videoId,
  linkIsExternal,
}) => {
  return (
    <div className="px-0 md:px-40 py-2 md:w-full animate-fadeInUp">
      <div className="h-full overflow-hidden duration-300 ease-in-out flex">
        {/* Left Section - Image */}
        {videoId ? (
          <div className="w-1/3 flex-shrink-0">
            <YouTubeVideo videoId={videoId} />
          </div>
        ) : (
          <div className="w-1/3 flex-shrink-0">
            <img
              className="h-48 w-full object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105 rounded-lg"
              src={image}
              alt="content"
            />
          </div>
        )}

        {/* Right Section - Text Content */}
        <div className="p-6 w-2/3 bg-white flex flex-col justify-between">
          <div>
            <h2 className="tracking-widest text-xs title-font font-medium text-primaryColor mb-1 uppercase">
              {category}
            </h2>
            <h1 className="title-font text-lg font-bold text-gray-900 mb-3">
              {title}
            </h1>
            <p className="leading-relaxed text-gray-700 mb-3 line-clamp-2">
              {description}
            </p>
          </div>

          {/* Bottom Section - Link */}
          <div className="flex items-center flex-wrap">
            {linkIsExternal ? (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primaryColor inline-flex items-center transition-transform duration-300 ease-in-out transform hover:translate-x-1"
              >
                {linkText}
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            ) : (
              <Link
                to={`${link}/${id}`}
                className="text-primaryColor inline-flex items-center transition-transform duration-300 ease-in-out transform hover:translate-x-1"
              >
                {linkText}
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StackCard;
