import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import logoImage from "../../assets/images/logo5cropped.png";
import { newsList } from "../../data/news";
import Button from "../../components/button";
import { MenuItem } from "./interface";

const menuItems: MenuItem[] = [
  { title: "Company", link: "/company" },
  {
    title: "Solutions",
    subItems: [
      { title: "Digital Strategy", link: "/digital-strategy" },
      { title: "Technology Modernisation", link: "/technology-modernisation" },
    ],
  },
  { title: "Industries", link: "/industries" },
  {
    title: "Resources",
    subItems: [
      { title: "News", link: "/news" },
      { title: "Research", link: "/research" },
    ],
  },
  { title: "Contact Us", link: "/contact-us" },
];

const Menu = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [sortedNewsList] = useState(
    newsList.sort(
      (a, b) =>
        new Date(a.createdDate || 0).getTime() -
        new Date(b.createdDate || 0).getTime()
    )
  );

  const handlePrev = () => {
    setCarouselIndex((prev) => (prev === 0 ? 4 : prev - 1));
  };

  const handleNext = () => {
    setCarouselIndex((prev) => (prev === 4 ? 0 : prev + 1));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setShowCarousel(true), 1000);

    // Run the curtain animation
    const curtainTimer = setTimeout(() => setAnimationFinished(true), 1000);
    return () => clearTimeout(curtainTimer);
  }, []);

  return (
    <div className="relative">
      {/* Curtain effect */}
      {!animationFinished && (
        <div className="fixed inset-0 z-50 bg-primaryColor animate-slide-up"></div>
      )}

      <div
        className={`fixed inset-0 bg-menuBgColor2 text-menuTextColor z-40 transition-all duration-500 ease-in-out ${
          animationFinished
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-full"
        }`}
        style={{
          backgroundImage:
            "radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px), radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px)",
          backgroundSize: "50px 50px",
          backgroundPosition: "0 0, 25px 25px",
        }}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-5">
          <Link to={"/"}>
            <img
              src={logoImage}
              alt="Matadigits Logo"
              className="w-[150px] md:w-[170px] ml-4 transition-transform duration-500 hover:scale-110"
            />
          </Link>
          <Link
            to="/contact-us"
            className="hidden md:block transition-opacity duration-500 hover:opacity-80"
          >
            <button className="bg-white text-primaryColor font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition">
              Contact Us
            </button>
          </Link>
        </div>

        {/* Menu */}
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-8 space-y-4">
            {menuItems.map((item, index) => (
              <div key={index}>
                {item.subItems ? (
                  <button
                    className="text-3xl md:text-6xl w-full text-left flex items-center transition-transform duration-300 hover:scale-105"
                    onClick={() =>
                      setOpenIndex(openIndex === index ? null : index)
                    }
                  >
                    {item.title}
                    {/* Icon with rotation animation */}
                    <span
                      className={`ml-10 transform transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      {openIndex === index ? " -" : " +"}
                    </span>
                  </button>
                ) : (
                  <Link
                    to={item.link ?? "/"}
                    className="text-3xl md:text-6xl w-full text-left transition-transform duration-300 hover:scale-105"
                  >
                    <button className="w-full text-left flex justify-between items-center transition-transform duration-300 hover:scale-105">
                      {item.title}
                    </button>
                  </Link>
                )}
                <Transition
                  show={openIndex === index}
                  enter="transition-opacity duration-300 ease-out"
                  enterFrom="opacity-0 translate-y-2"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition-opacity duration-300 ease-in"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {item.subItems && (
                    <div className="pl-10 pt-5 space-y-2">
                      {item.subItems.map((subItem, subIndex) => (
                        <Link
                          to={subItem.link}
                          key={subIndex}
                          className="text-2xl flex justify-between hover:underline transition-all duration-300 hover:text-primaryColor"
                        >
                          {subItem.title}
                          <span>→</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </Transition>
              </div>
            ))}
          </div>

          {/* Carousel Section - Hidden on mobile */}
          <div className="hidden md:block w-2/5 p-8 absolute top-20 right-0">
            <Transition
              key={carouselIndex} // Use carouselIndex as key to trigger re-animation
              appear
              show={showCarousel}
              enter="transition-opacity transform duration-500 ease-out"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-opacity transform duration-500 ease-in"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-10"
            >
              <a
                href={sortedNewsList[carouselIndex]?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <div className="flex justify-between">
                  <span className="text-1xl">Explore content</span>
                  <span className="ml-4 text-sm">{carouselIndex + 1} / 5</span>
                </div>
                <div className="p-0 rounded-lg mt-4 hover:shadow-2xl transition-all duration-500">
                  <img
                    src={sortedNewsList[carouselIndex]?.image}
                    alt="Carousel content"
                    className="w-full h-42 object-cover max-h-60 rounded-lg shadow-lg"
                  />
                  <div className="text-2xl text-primaryColor pt-5 pb-3 pr-2">
                    {sortedNewsList[carouselIndex]?.title}
                  </div>
                </div>
              </a>
            </Transition>
          </div>
        </div>

        {/* Footer Links */}
        <div className="flex justify-between items-end fixed bottom-0 left-0 w-full p-8 transition-opacity duration-500 ease-in-out">
          {/* Left side links (Privacy Policy & Terms of Use) */}
          <div className="space-x-10">
            <Link
              to="/privacy-policy"
              className="hover:underline transition-all duration-300 hover:text-primaryColor"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-of-use"
              className="hover:underline transition-all duration-300 hover:text-primaryColor"
            >
              Terms of Use
            </Link>
          </div>

          {/* Right side: buttons & news link */}
          <div className="flex items-end w-2/5 justify-between pl-8">
            {/* Buttons for Previous and Next */}
            <div className="flex space-x-2">
              <button
                onClick={handlePrev}
                className="border-2 border-primaryColor text-primaryColor text-4xl pb-1 rounded-full h-20 w-20 shadow-lg hover:shadow-2xl transition-all duration-300"
              >
                {"<"}
              </button>
              <button
                onClick={handleNext}
                className="border-2 border-primaryColor text-primaryColor text-4xl pb-1 rounded-full h-20 w-20 shadow-lg hover:shadow-2xl transition-all duration-300"
              >
                {">"}
              </button>
            </div>

            {/* News Link */}
            <Link
              to="/news"
              className="hover:underline transition-all duration-300 hover:text-primaryColor"
            >
              See all news
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
