import { useEffect } from "react";
import GridCard from "../../components/gridCard";
import PageTitle from "../../components/pageTitle";
import { duplicateStories } from "../../data/stories";

const ResourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mx-4">
      <PageTitle text="Resources" />
      <div className="flex flex-wrap -m-4 mx-4">
        {duplicateStories.map((section) => GridCard({ ...section }))}
      </div>
    </div>
  );
};

export default ResourcesPage;
