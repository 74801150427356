import { useEffect, useState } from "react";
import { newsList } from "../../data/news";
import "./styles.css";
import { Link } from "react-router-dom";
import StackCard from "../../components/stackCard";
import GridCard from "../../components/gridCard"; // Assuming you have GridCard component

const NewsPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size to toggle between StackCard and GridCard
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile width as <= 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up the listener
  }, []);

  return (
    <div className="bg-white px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[85vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxNews mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>
        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-4xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            World News
          </h1>
        </div>
        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Stay updated with the latest stories, insights, and developments
              shaping our world. Our curated news brings you the most important
              headlines, in-depth analysis, and real-time updates from across
              various industries. Explore what's happening today and stay
              informed.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Let's talk
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Cards Section */}
      <div className="flex flex-wrap mt-10 mx-[-1rem] md:mx-[-2rem]">
        {newsList
          .sort(
            (a, b) =>
              new Date(a.createdDate || 0).getTime() -
              new Date(b.createdDate || 0).getTime()
          ) // Sort by createdDate, with fallback for undefined
          .map((section) =>
            isMobile ? (
              <GridCard key={section.id} {...section} /> // Render GridCard on mobile
            ) : (
              <StackCard key={section.id} {...section} /> // Render StackCard on desktop
            )
          )}
      </div>
    </div>
  );
};

export default NewsPage;
