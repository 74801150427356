import React from "react";
import { IPageTitleProps } from "./interface";

const PageTitle: React.FC<IPageTitleProps> = ({
  text,
  color = "text-gray-900", // default color class
  fontSize = "text-5xl sm:text-6xl", // larger default font size classes
  marginTop = "mt-0",
  marginBottom = "mb-0",
}) => {
  return (
    <h1
      className={`title-font text-center ${marginBottom} ${color} ${fontSize} ${marginTop}`}
    >
      {text}
    </h1>
  );
};

export default PageTitle;
