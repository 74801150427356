import { useEffect } from "react";
import vrImage from "../../assets/images/vr.jpg";
import { Link } from "react-router-dom";
import "./styles.css"; // Import the CSS file for parallax effect

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto py-0 px-6 md:px-12 space-y-12 md:space-y-16">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[85vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallax mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>

        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-5xl md:text-8xl text-white drop-shadow-lg mb-4 md:mb-6">
            Engaging Digital Customer Experience
          </h1>
        </div>

        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Bringing pragmatism to digital customer and social intelligence
              and analytics for enhanced customer onboarding, engagement, and
              retention. Decision making is enhanced by rich data made
              accessible to the business by leveraging AI and Machine Learning
              pragmatically.
            </p>
            <Link to="/menu">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-3 text-base md:text-lg font-semibold text-white bg-primaryColor rounded-full shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Get Started
              </button>
            </Link>
          </div>
        </div>

        <Link
          to="/company"
          className="absolute bottom-8 right-4 md:right-8 z-10 bg-white text-primaryColor p-4 rounded-lg shadow-lg hover:bg-gray-100 hidden md:block"
        >
          Our Company
        </Link>
      </section>

      {/* What We Do Section */}
      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
        {/* Title on the left third */}
        <div className="md:col-span-1 space-y-6 md:space-y-8">
          <h2 className="text-2xl md:text-3xl font-semibold text-primaryColor">
            What We Do
          </h2>
        </div>
        {/* Paragraph on the right two-thirds */}
        <div className="md:col-span-2 space-y-6 md:space-y-8">
          <p className="text-base md:text-2xl text-gray-700 leading-relaxed">
            Matadigits is bringing new pragmatism to digital customer
            engagement, understanding of changing customer behavior, and social
            intelligence analytics. Companies and governments alike are
            grappling with the fast-paced and compounding needs of customers and
            citizens.
          </p>
          <p className="text-base md:text-2xl text-gray-700 leading-relaxed">
            Digitisation is now firmly established and no longer an option.
            Simultaneously, the rising utility of Artificial Intelligence and
            Machine Learning in analytics is adding complexity to the trend.
          </p>
        </div>
      </section>

      {/* What We Do Section */}
      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
        {/* Title on the left third */}
        <div className="md:col-span-1 space-y-6 md:space-y-8">
          <h2 className="text-2xl md:text-3xl font-semibold text-primaryColor">
            Quote
          </h2>
        </div>
        {/* Paragraph on the right two-thirds */}
        <div className="md:col-span-2 space-y-6 md:space-y-8">
          <p className="text-base md:text-2xl text-gray-700 leading-relaxed">
            “The illusion of data as “objective” conceals that it rarely shows
            you the whole picture. Making decisions based on the easiest data to
            gather is a recipe for disaster.” – Pavel Samsonov.
          </p>
        </div>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
        {/* Title on the left third */}
        <div className="md:col-span-1 space-y-6 md:space-y-8">
          <h2 className="text-2xl md:text-3xl font-semibold text-primaryColor">
            Engaging Digital Customer Experience
          </h2>
        </div>
        {/* Paragraph on the right two-thirds */}
        <div className="md:col-span-2 space-y-6 md:space-y-8">
          <p className="text-base md:text-2xl text-gray-700 leading-relaxed">
            Bringing pragmatism to digital customer and social intelligence and
            analytics for enhanced customer onboarding, engagement, and
            retention. Decision making is enhanced by rich data made accessible
            to the business by leveraging AI and Machine Learning pragmatically.
          </p>
          <div className="flex items-center justify-center col-span-3 md:col-span-1">
            <img
              src={vrImage}
              alt="Digital Engagement"
              className="w-full h-1/2 rounded-lg shadow-lg transition-transform duration-700 hover:scale-105"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
